import React, { useContext } from 'react'
import sortAtoZ from '../../utils/functions/sortAtoZ'
import useMedia from '../../utils/hooks/useMedia'
import { capitalise } from '../../utils/transforms/transform-string'
import DropdownWithValue from '../ui/dropdown/DropdownWithValue'
import { CourseMapsContext } from '../wrappers/CourseMapsContextWrapper'
import c from './filters.module.scss'

const filterSelectOnChangeHandler =
  (filter, filters, setFilters) => (onChangeValue) => {
    let copy = { ...filters }
    copy[filter].selected = onChangeValue
    setFilters(copy)
  }

const getFilterColumnCount = (filtersCount, size) => {
  let columnCount
  if (size === 'L') {
    if (filtersCount <= 4) {
      columnCount = filtersCount
    } else {
      columnCount = 4
    }
  }

  if (size === 'M') {
    if (filtersCount <= 2) {
      columnCount = filtersCount
    } else {
      columnCount = 2
    }
  }

  if (size === 'S') {
    columnCount = 1
  }

  return columnCount
}

const Filters = () => {
  const size = useMedia()
  const { filters, setFilters, filtersCount } = useContext(CourseMapsContext)

  return (
    <section className={c.filters} aria-label='map filters'>
      <div
        className={c.filtersCard}
        style={{
          gridTemplateColumns: `repeat(${getFilterColumnCount(
            filtersCount,
            size
          )},1fr)`,
        }}>
        {Object.keys(filters).map((filter, i) => {
          const filterData = filters[filter]
          return (
            <div className={c.filter} key={`${filterData.key}${i}`}>
              <label id={filterData.key}> {capitalise(filterData.key)}</label>
              <DropdownWithValue
                listId={`${filterData.key}List`}
                ariaLabelledby={filterData.key}
                value={filterData.selected || filterData.values[0]}
                onChange={filterSelectOnChangeHandler(
                  filter,
                  filters,
                  setFilters
                )}
                data={sortAtoZ(filterData.values).map((filter) => {
                  return {
                    id: filter,
                    text: capitalise(filter),
                    value: filter,
                  }
                })}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Filters
