import { useEffect, useState } from 'react'

const useMedia = (s = 800, m = 1200) => {
  //3 responsive sizes
  const queries = {
    S: `(max-width: ${s}px)`,
    M: `(min-width: ${s + 1}px) and (max-width: ${m}px)`,
    L: `(min-width:  ${m + 1}px)`,
  }
  const keys = Object.keys(queries)

  //Set sizes
  const [size, setSize] = useState()

  //Check individual media and if it matches
  const checkSize = (media, matches) => {
    if (matches & (size !== media)) {
      setSize(media)
    }
  }

  useEffect(() => {
    keys.forEach((media) => {
      const mediaQuery = window.matchMedia(queries[media])
      checkSize(media, mediaQuery.matches)
      mediaQuery.onchange = () => {
        checkSize(media, mediaQuery.matches)
      }
    })
  })
  return size
}

export default useMedia
