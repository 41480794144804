import { blockify } from './blockify'

export const transformMap = (map) => {
  let blockIndex = 0

  map.map?.forEach((year, y) => {
    year.periods.forEach((period, tp) => {
      let unitIndex = 0
      let unitPlaceHolderIndex = 0
      period.units.forEach((unit, u) => {
        const isUnit = unit.unit_code
        unit.unit_code ? unitIndex++ : unitPlaceHolderIndex++
        map.map[y].periods[tp].units[u] = blockify(
          unit.unit_code,
          unit.description,
          unit.label || null,
          unit.creditPoints || null,
          blockIndex,
          isUnit ? unitIndex : unitPlaceHolderIndex
        )
        blockIndex++
      })
    })
  })
  return map
}
