import React from 'react'
import DropdownItem from './DropdownItem'
import c from './dropdownWithValue.module.scss'
const DropdownSection = ({
  startIndex,
  selected,
  category,
  categoryLabel,
  handleFocus,
  handleSelect,
  handleKeyDown,
  handleClose,
  handleSelectText,
  innerRef,
  categoryItems,
}) => {
  return (
    <div className={c.category}>
      <div className={c.categoryLabel}>
        <span>{category}</span> <span>{categoryLabel}</span>
      </div>
      {categoryItems.map((child, i) => (
        <DropdownItem
          index={startIndex + i}
          key={startIndex + i}
          selected={selected}
          handleFocus={handleFocus}
          handleSelect={handleSelect}
          handleKeyDown={handleKeyDown}
          handleClose={handleClose}
          handleSelectText={handleSelectText}
          innerRef={(e) => (innerRef.current[startIndex + i] = e)}
          label={child.label}
          value={child.value}>
          {child.text}
        </DropdownItem>
      ))}
    </div>
  )
}

export default DropdownSection
