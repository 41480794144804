import Period from '../period/Period'
import c from './year.module.scss'

const Year = ({ year }) => {
  return (
    <li className={c.year}>
      <h2>Year {year.year}</h2>
      <ul className={c.periods}>
        {year.periods.map((period, i) => (
          <Period period={period} key={i} />
        ))}
      </ul>
    </li>
  )
}

export default Year
