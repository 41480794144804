import { useContext } from 'react'
import CourseLabel from '../../ui/course-label/CourseLabel'
import CreditPointsTag from '../../ui/credit-points-tag/CreditPointsTag'
import { SizeContext } from '../../wrappers/SizeContextWrapper'
import c from './grid-block.module.scss'

const getBlockColumnSpan = (cp, size) => {
  let span
  if (size === 'L') {
    switch (true) {
      case cp > 0 && cp < 24:
        span = Math.floor(cp / 6)
        break
      case cp > 0 && cp > 24:
        span = 4
        break
      default:
        span = 1
    }
  } else {
    switch (true) {
      case cp > 6:
        span = 2
        break
      default:
        span = 1
    }
  }
  return span
}

//TODO refactor virtuallyHidden items render method

const GridBlock = ({ block }) => {
  const { size } = useContext(SizeContext)

  return (
    <li
      className={`${!block.name ? c.elective : ''} ${c.gridBlock}`}
      style={{
        gridColumn: `auto / span ${getBlockColumnSpan(
          block.creditPoints,
          size
        )}`,
        animationDelay: `${block.blockIndex * 0.075}s`,
      }}>
      <p className={c.virtuallyHidden}>
        {block.name
          ? `Unit card ${block.periodBlockTypeIndex}:`
          : `Unit place holder card ${block.periodBlockTypeIndex}:`}{' '}
      </p>
      {block.courseLabel && (
        <>
          <span className={c.virtuallyHidden}>Course label:</span>
          <CourseLabel
            label={block.courseLabel}
            displayText={block.courseLabel}
          />
        </>
      )}
      {block.name && (
        <span className={c.unitCode}>
          <span className={c.virtuallyHidden}>Unit code:</span>
          <abbr title={block.name.split('').join(' ')}>{block.name}</abbr>
        </span>
      )}
      {block.description && (
        <p className={c.title}>
          <span className={c.virtuallyHidden}>Description:</span>
          {block.description}
        </p>
      )}
      {block.name && (
        <>
          <span className={c.virtuallyHidden}>Credit points:</span>
          <CreditPointsTag creditPoints={block.creditPoints} />
        </>
      )}
    </li>
  )
}

export default GridBlock
