import React from 'react'

const Illustration = ({ children }) => {
  return <>{children}</>
}

export default Illustration

// illustrations

//Error
Illustration.Error = () => (
  <svg
    width='339'
    height='230'
    viewBox='0 0 339 230'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect opacity='0.5' y='144' width='339' height='74' rx='8' fill='#E8EEF4' />
    <rect
      x='69'
      y='50'
      width='201.4'
      height='146'
      rx='7'
      stroke='#A1B6CA'
      strokeWidth='2'
    />
    <rect x='80' y='63' width='179' height='120' rx='4' fill='#C5D2DF' />
    <g filter='url(#filter0_d)'>
      <rect x='15' y='125' width='45' height='45' rx='22.5' fill='#C5D2DF' />
    </g>
    <path
      d='M33.6 137H41.4C42.0896 137 42.7509 137.274 43.2385 137.762C43.7261 138.249 44 138.91 44 139.6V157.8L37.5 153.9L31 157.8V139.6C31 138.91 31.2739 138.249 31.7615 137.762C32.2491 137.274 32.9104 137 33.6 137Z'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter1_d)'>
      <rect x='192' y='8' width='35' height='35' rx='17.5' fill='#C5D2DF' />
    </g>
    <path
      d='M208.685 29.1372C211.875 29.1372 214.46 26.6508 214.46 23.5838C214.46 20.5167 211.875 18.0303 208.685 18.0303C205.495 18.0303 202.909 20.5167 202.909 23.5838C202.909 26.6508 205.495 29.1372 208.685 29.1372Z'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M217.761 32.3105L212.811 27.5504'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter2_d)'>
      <rect x='281' y='67' width='30' height='30' rx='15' fill='#C5D2DF' />
    </g>
    <path
      d='M296.178 87.9778V87.9858'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M296.083 84.1111C296.06 83.7266 296.169 83.3454 296.393 83.0249C296.617 82.7044 296.944 82.462 297.325 82.3343C297.792 82.164 298.211 81.8927 298.549 81.5418C298.887 81.1908 299.135 80.7698 299.273 80.3118C299.412 79.8538 299.437 79.3714 299.347 78.9025C299.257 78.4336 299.054 77.9911 298.754 77.6097C298.455 77.2284 298.066 76.9186 297.62 76.7048C297.173 76.4911 296.68 76.3791 296.181 76.3778C295.681 76.3765 295.188 76.4858 294.74 76.6972C294.292 76.9086 293.902 77.2163 293.6 77.5961'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter3_d)'>
      <rect x='250' y='166' width='40' height='40' rx='4' fill='white' />
    </g>
    <path
      d='M260 180H280.8'
      stroke='#C5D2DF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M260 192H280.8'
      stroke='#C5D2DF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='169' cy='123' r='35' stroke='#204F88' strokeWidth='10' />
    <path d='M194.5 98L144 148.5' stroke='#204F88' strokeWidth='10' />
    <defs>
      <filter
        id='filter0_d'
        x='5'
        y='117'
        width='65'
        height='65'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d'
        x='182'
        y='0'
        width='55'
        height='55'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_d'
        x='271'
        y='59'
        width='50'
        height='50'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter3_d'
        x='230'
        y='150'
        width='80'
        height='80'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='10' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

//Error
Illustration.Error = () => (
  <svg
    width='339'
    height='230'
    viewBox='0 0 339 230'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect opacity='0.5' y='144' width='339' height='74' rx='8' fill='#E8EEF4' />
    <rect
      x='69'
      y='50'
      width='201.4'
      height='146'
      rx='7'
      stroke='#A1B6CA'
      strokeWidth='2'
    />
    <rect x='80' y='63' width='179' height='120' rx='4' fill='#C5D2DF' />
    <g filter='url(#filter0_d)'>
      <rect x='15' y='125' width='45' height='45' rx='22.5' fill='#C5D2DF' />
    </g>
    <path
      d='M33.6 137H41.4C42.0896 137 42.7509 137.274 43.2385 137.762C43.7261 138.249 44 138.91 44 139.6V157.8L37.5 153.9L31 157.8V139.6C31 138.91 31.2739 138.249 31.7615 137.762C32.2491 137.274 32.9104 137 33.6 137Z'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter1_d)'>
      <rect x='192' y='8' width='35' height='35' rx='17.5' fill='#C5D2DF' />
    </g>
    <path
      d='M208.685 29.1372C211.875 29.1372 214.46 26.6508 214.46 23.5838C214.46 20.5167 211.875 18.0303 208.685 18.0303C205.495 18.0303 202.909 20.5167 202.909 23.5838C202.909 26.6508 205.495 29.1372 208.685 29.1372Z'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M217.761 32.3105L212.811 27.5504'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter2_d)'>
      <rect x='281' y='67' width='30' height='30' rx='15' fill='#C5D2DF' />
    </g>
    <path
      d='M296.178 87.9778V87.9858'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M296.083 84.1111C296.06 83.7266 296.169 83.3454 296.393 83.0249C296.617 82.7044 296.944 82.462 297.325 82.3343C297.792 82.164 298.211 81.8927 298.549 81.5418C298.887 81.1908 299.135 80.7698 299.273 80.3118C299.412 79.8538 299.437 79.3714 299.347 78.9025C299.257 78.4336 299.054 77.9911 298.754 77.6097C298.455 77.2284 298.066 76.9186 297.62 76.7048C297.173 76.4911 296.68 76.3791 296.181 76.3778C295.681 76.3765 295.188 76.4858 294.74 76.6972C294.292 76.9086 293.902 77.2163 293.6 77.5961'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter3_d)'>
      <rect x='250' y='166' width='40' height='40' rx='4' fill='white' />
    </g>
    <path
      d='M260 180H280.8'
      stroke='#C5D2DF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M260 192H280.8'
      stroke='#C5D2DF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='169' cy='123' r='35' stroke='#204F88' strokeWidth='10' />
    <path d='M194.5 98L144 148.5' stroke='#204F88' strokeWidth='10' />
    <defs>
      <filter
        id='filter0_d'
        x='5'
        y='117'
        width='65'
        height='65'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d'
        x='182'
        y='0'
        width='55'
        height='55'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_d'
        x='271'
        y='59'
        width='50'
        height='50'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <filter
        id='filter3_d'
        x='230'
        y='150'
        width='80'
        height='80'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='10' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

Illustration.LoadingPlan = () => (
  <svg
    width='350'
    height='250'
    viewBox='0 0 350 250'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M75 54C75 51.7909 76.7909 50 79 50H271C273.209 50 275 51.7909 275 54V196C275 198.209 273.209 200 271 200H79C76.7909 200 75 198.209 75 196V54Z'
      fill='#C5D2DF'
    />
    <path
      d='M138 79C138 76.7909 139.791 75 142 75H166C168.209 75 170 76.7909 170 79V109C170 111.209 168.209 113 166 113H142C139.791 113 138 111.209 138 109V79Z'
      fill='#E8EEF4'
    />
    <path
      d='M138 127C138 124.791 139.791 123 142 123H166C168.209 123 170 124.791 170 127V157C170 159.209 168.209 161 166 161H142C139.791 161 138 159.209 138 157V127Z'
      fill='#E8EEF4'
    />
    <path
      d='M96 127C96 124.791 97.7909 123 100 123H124C126.209 123 128 124.791 128 127V157C128 159.209 126.209 161 124 161H100C97.7909 161 96 159.209 96 157V127Z'
      fill='#E8EEF4'
    />
    <path
      d='M180 79C180 76.7909 181.791 75 184 75H208C210.209 75 212 76.7909 212 79V109C212 111.209 210.209 113 208 113H184C181.791 113 180 111.209 180 109V79Z'
      fill='#E8EEF4'
    />
    <rect x='144' y='103' width='20' height='5' rx='2.5' fill='#A1B6CA' />
    <rect x='144' y='151' width='20' height='5' rx='2.5' fill='#A1B6CA' />
    <rect x='102' y='151' width='20' height='5' rx='2.5' fill='#A1B6CA' />
    <rect x='186' y='103' width='20' height='5' rx='2.5' fill='#A1B6CA' />
  </svg>
)

Illustration.LoadingCrane = () => (
  <svg
    fill='none'
    height='200'
    viewBox='0 0 143 200'
    width='143'
    xmlns='http://www.w3.org/2000/svg'>
    <g stroke='#c5d2df' strokeLinejoin='round' strokeWidth='2'>
      <path d='m30 170h20l-20-20z' />
      <path d='m30 130h20l-20-20z' />
      <path d='m97 30v-15l15 15z' />
      <path d='m127 30v-15l15 15z' />
      <path d='m20 30v-15l10 15z' />
      <path d='m30 90h20l-20-20z' />
      <path d='m82 30v-15l-16 15z' />
      <path d='m10 30v-15l-9 15z' />
      <path d='m30 150h20l-20-20z' />
      <path d='m30 110h20l-20-20z' />
      <path d='m97 30v-15l-15 15z' />
      <path d='m127 30v-15l-15 15z' />
      <path d='m20 30v-15l-10 15z' />
      <path d='m30 70h20l-20-20z' />
      <path d='m66 30v-15l-15 15z' />
      <path d='m50 150h-20l20 20z' />
      <path d='m50 110h-20l20 20z' />
      <path d='m50 70h-20l20 20z' />
      <path d='m66 15v15l16-15z' />
      <path d='m50 130h-20l20 20z' />
      <path d='m50 90h-20l20 20z' />
      <path d='m82 15v15l15-15z' />
      <path d='m112 15v15l-15-15z' />
      <path d='m112 15v15l15-15z' />
      <path d='m10 15v15l10-15z' />
      <path d='m50 50h-20l20 20z' />
      <path d='m45 30-6-29-9 29z' />
    </g>
    <path
      d='m20.5 194c0-.828.6716-1.5 1.5-1.5h36c.8284 0 1.5.672 1.5 1.5v4c0 .828-.6716 1.5-1.5 1.5h-36c-.8284 0-1.5-.672-1.5-1.5z'
      fill='#183c67'
      stroke='#183c67'
    />
    <path
      d='m1 40v-10.4126c0-.3835.11026-.7589.31764-1.0815l8.37739-13.0315c.19942-.3102.48077-.5593.81287-.7196l28.4921-13.7548 88 14v15'
      stroke='#c5d2df'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='m30 170.5h-.5v.5 20 .5h.5 20 .5v-.5-20-.5h-.5z'
      fill='#c5d2df'
      stroke='#c5d2df'
    />
    <path
      d='m1 29.5c-.276142 0-.5.2239-.5.5v10c0 .2761.223858.5.5.5h19c.2761 0 .5-.2239.5-.5v-10c0-.2761-.2239-.5-.5-.5z'
      fill='#a1b6ca'
      stroke='#a1b6ca'
      strokeLinejoin='round'
    />
    <path
      d='m30 29.5c-.2761 0-.5.2239-.5.5v20c0 .2761.2239.5.5.5h20c.2761 0 .5-.2239.5-.5v-20c0-.2761-.2239-.5-.5-.5z'
      fill='#a1b6ca'
      stroke='#a1b6ca'
      strokeLinejoin='round'
    />
    <circle cx='112' cy='34' fill='#c5d2df' r='3' />
  </svg>
)

Illustration.LoadingLadder = () => (
  <svg
    fill='none'
    height='73'
    viewBox='0 0 59 73'
    width='59'
    xmlns='http://www.w3.org/2000/svg'>
    <rect fill='#204f88' height='2' rx='1' width='34' x='12' />
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='m41.1418 2c.5585 0 .9683.52459.8333 1.06643l-17.2718 69.28257c-.0954.3825-.439.651-.8332.651-.5584 0-.9683-.5246-.8332-1.0664l2.3891-9.5836h-21.24098l-2.49271 9.999c-.09536.3825-.43896.651-.83321.651-.558422 0-.968292-.5246-.8332135-1.0664l17.2718135-69.2826c.0953-.38254.4389-.651.8332-.651.5584 0 .9683.52459.8332 1.06643l-3.2741 13.13357h21.2409l3.3777-13.549c.0954-.38254.439-.651.8332-.651zm-25.8943 15.975h21.241l-1.7701 7.1001h-21.241zm-2.2126 8.8751h21.241l-1.7699 7.0998-21.241.0001zm-2.2124 8.8749h21.241l-1.77 7.1h-21.241zm-2.21242 8.875h21.24092l-1.77 7.1001h-21.24102zm-2.2126 8.8751h21.24102l-1.77 7.0999h-21.24098z'
        fill='#a1b6ca'
      />
      <path
        d='m17.8543 2c-.5585 0-.9683.52459-.8333 1.06643l17.2719 69.28257c.0954.3825.439.651.8332.651.5585 0 .9683-.5246.8333-1.0664l-2.3892-9.5836h21.2409l2.4927 9.999c.0954.3825.439.651.8332.651.5584 0 .9683-.5246.8332-1.0664l-17.2718-69.2826c-.0954-.38254-.439-.651-.8333-.651-.5584 0-.9683.52459-.8332 1.06643l3.2741 13.13357h-21.2408l-3.3777-13.549c-.0954-.38254-.439-.651-.8332-.651zm25.8943 15.975h-21.2409l1.77 7.1001h21.2409zm2.2125 8.8751h-21.2409l1.77 7.0998 21.2409.0001zm2.2125 8.8749h-21.2409l1.77 7.1h21.2409zm2.2121 8.8733v.0017h-21.2405l1.7703 7.1011v-.001h21.2406zm2.2129 8.8768h-21.2409l1.77 7.0999h21.2409z'
        fill='#204f88'
      />
    </g>
  </svg>
)
Illustration.LoadingPerson1 = () => (
  <svg
    fill='none'
    height='77'
    viewBox='0 0 24 77'
    width='24'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m14.0122 23.8001h-6.98813c-1.16465-6.9333-3.52399-19.8001-3.52399-19.8001s.14226.04011-2.50008.00015c-.000082 3.49985 2.53002 25.69325 4.02747 37.99995h13.47703v-16.64l4.4924-21.31999h-1.9966z'
      fill='#e8eef4'
    />
    <ellipse cx='11' cy='16.5' fill='#a1b6ca' rx='4' ry='3.5' />
    <circle cx='11' cy='18' fill='#183c67' r='4' />
    <circle cx='2' cy='2' fill='#e8eef4' r='2' />
    <circle cx='22' cy='2' fill='#e8eef4' r='2' />
    <path
      d='m17.759 42h-11.98361s2.21376 29.1449 2.21918 30.0725c.00543.9275.00543 4.9275 1.00543 4.9275s1-4 1-5 1.1014-27.2174 1.1014-27.2174h1.7754l1.1232 27.2174c0 2.5.1734 5 1 5s1-2 1-5z'
      fill='#183c67'
    />
  </svg>
)
Illustration.LoadingPerson2 = () => (
  <svg
    fill='none'
    height='47'
    viewBox='0 0 62 47'
    width='62'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m46.0691 44.0792c-1.1263-.9008-8.8745-17.989-12.6078-26.4205l6.4971-1.0187c.7583 2.3822 1.777 8.8793 2.3622 12.3661.4681 2.7894 4.2679 11.7594 6.1093 15.8957l-5.2409 1.6683c-.7623.2427-.8375-.433-.7798-.8012z'
      fill='#183c67'
    />
    <path
      d='m32.0005 18.5958-11.2954-4.7473-6.1937 1.9716c-2.8587.9099-10.75499.1702-10.59054-.3019.8222-2.3608.70727-1.6244.82269-2.3608 1.79036.1297 6.06845.2722 8.85785-.196 3.4868-.5852 6.8433-3.2278 7.1681-3.85591.2599-.50248 2.0141-.81601 2.8587-.90996l11.8723 1.40029z'
      fill='#a1b6ca'
    />
    <path
      d='m48.9459 18.5011c-.3811.1213-11.334.1097-16.7628.0887l3.4424-8.96661 12.5836 4.91471 7.6875-7.16955 4.0719 2.90155-.3247.6281-3.1406-1.62387c-2.3603 3.02507-7.1761 9.10567-7.5573 9.22697z'
      fill='#204f88'
    />
    <circle
      cx='18.1256'
      cy='5.74874'
      fill='#e8eef4'
      r='4.5'
      transform='matrix(.95288886 -.30331967 .30331967 .95288886 -.889788 5.768681)'
    />
    <path
      d='m18.2996 4.64423c-1.6459.10416-3.2795-1.01418-3.7993-1.54833.6496-1.25621 4.4996-3.499906 7.4592-.14139 1.9001 2.15614.5405 5.64132-.4595 6.14142-.3105-.42589-1.5974-1.39386-1.3375-1.89635.3247-.6281.8012-.77976.3462-2.2091-.364-1.14346-1.6244-.70727-2.2091-.34625z'
      fill='#183c67'
    />
    <circle
      cx='2.51242'
      cy='13.6082'
      fill='#c5d2df'
      r='2'
      transform='matrix(.95288886 -.30331967 .30331967 .95288886 -4.009272 1.403164)'
    />
  </svg>
)
Illustration.LoadingPerson2Arm = () => (
  <svg
    width='22'
    height='11'
    viewBox='0 0 22 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx='2.81125'
      cy='5.96564'
      r='2'
      transform='rotate(-17.6571 2.81125 5.96564)'
      fill='#A1B6CA'
    />
    <path
      d='M10.0888 5.74829C8.18302 6.35493 5.61289 5.42397 4.56605 4.88267L4.04668 8.19631C6.9993 8.13098 13.1904 7.90932 14.3339 7.54533C15.7632 7.09035 21.9784 3.53778 20.8739 3.36466C19.7693 3.19153 12.471 4.98999 10.0888 5.74829Z'
      fill='#183C67'
    />
  </svg>
)

Illustration.LoadingPerson1Top = () => (
  <svg
    fill='none'
    height='42'
    viewBox='0 0 24 42'
    width='24'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m14.0122 23.8001h-6.98813c-1.16465-6.9333-3.52399-19.8001-3.52399-19.8001s.14226.04011-2.50008.00015c-.000082 3.49985 2.53002 25.69325 4.02747 37.99995h13.47703v-16.64l4.4924-21.31999h-1.9966z'
      fill='#e8eef4'
    />
    <ellipse cx='11' cy='16.5' fill='#a1b6ca' rx='4' ry='3.5' />
    <circle cx='11' cy='18' fill='#183c67' r='4' />
    <g fill='#e8eef4'>
      <circle cx='2' cy='2' r='2' />
      <circle cx='22' cy='2' r='2' />
    </g>
  </svg>
)

Illustration.LoadingPerson1Bottom = () => (
  <svg
    fill='none'
    height='35'
    viewBox='0 0 13 35'
    width='13'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m12.759 0h-11.983609s2.213759 29.1449 2.219179 30.0725c.00543.9275.00543 4.9275 1.00543 4.9275s1-4 1-5 1.10143-27.21739 1.10143-27.21739h1.77534l1.12323 27.21739c0 2.5.17337 5 1 5 .8266 0 1-2 1-5z'
      fill='#183c67'
    />
  </svg>
)
