import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import sortAtoZ from '../../utils/functions/sortAtoZ'
import { RouterState } from '../../utils/Router'
import { getProgressionMaps } from '../../utils/services'
import { transformMap } from '../../utils/transforms/transform-map'

export const CourseMapsContext = createContext()

const hiddenFilterFields = ['code', 'year']

// get a unique set of filters and values from all the fetched maps
const getFilters = (maps) => {
  let filters = {}
  maps.forEach((doc) => {
    doc.filter_fields.forEach((filterField) => {
      if (hiddenFilterFields.includes(filterField)) return
      if (filters[filterField]) {
        if (!filters[filterField].values.includes(doc[filterField])) {
          filters[filterField].values.push(doc[filterField])
        }
      } else {
        filters[filterField] = {
          key: filterField,
          values: [doc[filterField]],
        }
      }
    })

    Object.keys(filters).forEach((f) => {
      let sorted = sortAtoZ(filters[f].values)
      filters[f].values = sorted
      filters[f]['selected'] = sorted[0]
    })
  })
  return filters
}
const CourseMapsContextWrapper = ({ children }) => {
  const [courseMaps, setCourseMaps] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  // get map params from route
  const { route } = useContext(RouterState)
  const year = route.path.split('/')[1]
  const code = route.path.split('/')[2]
  const mapParams = useRef({})

  // filters
  const [filters, setFilters] = useState(null)

  // filters count for styling
  // TODO refactor filters and remove filtersCount
  const filtersCount = filters && Object.keys(filters).length

  //on display map
  const filteredMap = courseMaps?.filter((map) => {
    return !map.filter_fields
      .filter((filter) => !hiddenFilterFields.includes(filter))
      .some((filter) => {
        return filters[filter]?.selected !== map[filter]
      })
  })[0]
  const [courseTitle, setCourseTitle] = useState(null)
  const onDisplayCourseTitle = filteredMap?.title
    ? filteredMap?.title
    : courseTitle

  // double degree course labels
  // A-Z sort ensure the same course label will always have the same colour

  const courseLabels =
    filteredMap?.doubleDegree &&
    filteredMap?.singleDegrees &&
    sortAtoZ(Object.keys(filteredMap?.singleDegrees))

  // fetch maps
  useEffect(() => {
    // prevent fetching maps twice
    if (mapParams.current.year === year && mapParams.current.code === code)
      return
    mapParams.current = { year, code }

    setLoading(true)
    getProgressionMaps(year, code).then((docs) => {
      //TODO Fetch error handling
      if (!docs.length) {
        setError(true)
      }
      // Set course title first in case the default filter selection has no map
      setCourseTitle(docs[1]?.title)
      setCourseMaps(docs.map((doc) => transformMap(doc)))
      setFilters(getFilters(docs))
      setLoading(false)
    })
  }, [year, code])

  const values = useMemo(
    () => ({
      year,
      code,
      courseMaps,
      filteredMap,
      onDisplayCourseTitle,
      filtersCount,
      filters,
      setFilters,
      courseLabels,
      loading,
      error,
    }),
    [
      year,
      code,
      courseMaps,
      filteredMap,
      onDisplayCourseTitle,
      filtersCount,
      filters,
      courseLabels,
      setFilters,
      loading,
      error,
    ]
  )

  return (
    <CourseMapsContext.Provider value={values}>
      {children}
    </CourseMapsContext.Provider>
  )
}

export default CourseMapsContextWrapper
