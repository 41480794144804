import React, { useContext } from 'react'
import { CourseMapsContext } from '../../wrappers/CourseMapsContextWrapper'
import c from './course-label.module.scss'

// TODO clean up all hard coded data
const courseLabelColors = [
  { color: '#86456C', borderColor: '#86456c7a' },
  { color: '#2D6C7A', borderColor: '#2d6c7a7e' },
]

const CourseLabel = ({ label, displayText }) => {
  const { courseLabels } = useContext(CourseMapsContext)
  // Data can be corrupted
  const colourIndex =
    courseLabels?.indexOf(label) !== -1 ? courseLabels?.indexOf(label) : 0

  return (
    <div
      className={c.courseLabel}
      style={{
        color: `${courseLabelColors[colourIndex].color}`,
        border: `1px solid ${courseLabelColors[colourIndex].borderColor}`,
      }}>
      {displayText}
    </div>
  )
}

export default CourseLabel
