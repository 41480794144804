export const getProgressionMaps = async (year, code) => {
  if (!year || !code) return []

  let url = `${process.env.REACT_APP_COURSE_MAP_SERVICE}/api/progression-map-api/progression-maps?code=${code}&year=${year}`

  try {
    const response = await fetch(url)
    if (!response.ok) {
      const json = await response.json()
      throw new Error(json.error)
    }
    return await response.json()
  } catch (e) {
    return e
  }
}
