import './App.scss'
import Main from './components/main/Main'
import TopNav from './components/top-nav/TopNav'

function App() {
  return (
    <>
      <TopNav />
      <Main />
    </>
  )
}

export default App
