import React from 'react'
import c from './error.module.scss'

const Error = () => {
  return (
    <div className={c.error}>
      <h1>The page you’re looking for can’t be found.</h1>
    </div>
  )
}

export default Error
