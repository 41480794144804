import React from 'react'
import { useState, useRef, useLayoutEffect } from 'react'
import c from './positionAwareMenu.module.scss'

const PositionAwareMenu = ({
  shown,
  adjustHorizontal = true,
  adjustVertical = true,
  fixed = false,
  left,
  top,
  marginTop,
  marginLeft,
  horizontalAlign = 'left',
  verticalAlign = 'down',
  offsetX = 0,
  offsetY = 0,
  className,
  onReposition,
  children,
}) => {
  //
  const [alignedLeft, setAlignedLeft] = useState(true)
  const [alignedDown, setAlignedDown] = useState(true)
  const menuRef = useRef(null)

  const getTranslation = () => {
    const horizontal = alignedLeft
      ? '0px'
      : `calc(${offsetX < 0 ? '-' : '+'} ${Math.abs(offsetX)}px)`

    const vertical = alignedDown
      ? '0px'
      : `calc(-100% ${offsetY < 0 ? '-' : '+'} ${Math.abs(offsetY)}px)`

    return `translate(${horizontal},${vertical})`
  }

  useLayoutEffect(() => {
    if (!shown || !menuRef.current) return

    const bounds = menuRef.current.getBoundingClientRect()
    const x = alignedLeft ? bounds.x : bounds.x + bounds.width
    const y = alignedDown ? bounds.y : bounds.y + bounds.height

    let left, down

    if (horizontalAlign === 'right') left = x - bounds.width < 0
    else if (horizontalAlign === 'left')
      left = !(x + bounds.width > window.innerWidth)

    if (verticalAlign === 'up') down = y - bounds.height > 0
    else if (verticalAlign === 'down')
      down = !(y + bounds.height > window.innerHeight)

    adjustHorizontal && setAlignedLeft(left)
    adjustVertical && setAlignedDown(down)

    // Callback to run effects whenever repositioned.
    if (onReposition) onReposition(left, down)
  }, [shown])

  // render
  return (
    <div
      className={[c.overlay, className].join(' ')}
      style={{
        left: left,
        top: top,
        marginTop: marginTop,
        marginLeft: marginLeft,
        position: fixed ? 'fixed' : 'absolute',
        right: horizontalAlign === 'right' ? '0px' : null,
      }}>
      <div
        className={c.container}
        ref={menuRef}
        style={{
          transform: getTranslation(),
        }}>
        {shown && children}
      </div>
    </div>
  )
}

export default PositionAwareMenu
