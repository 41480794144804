import React, { useContext, useEffect, useRef } from 'react'
import c from './main.module.scss'
import Filters from '../filters/Filters'
import Map from '../map/Map'
import Banner from '../banner/Banner'
import Error from '../Error/Error'
import Loading from '../loading/Loading'
import Notes from '../map/notes/Notes'
import PdfMapUrl from '../map/pdf-map-url/PdfMapUrl'
import CourseLabels from '../map/course-labels/CourseLabels'
import { CourseMapsContext } from '../wrappers/CourseMapsContextWrapper'

const Main = () => {
  const { filteredMap, filtersCount, error, loading } =
    useContext(CourseMapsContext)
  const loadingMessageRef = useRef()

  // auto focus on loading message for AX
  // TODO might consider using role status instead of this
  useEffect(() => {
    loadingMessageRef.current.focus()
  }, [])

  // render
  return (
    <>
      {error && <Error />}
      {!error && loading && (
        <div className={c.loading}>
          <Loading />
          <p tabIndex='-1' ref={loadingMessageRef}>
            Loading course progression maps...
          </p>
        </div>
      )}
      {!error && !loading && (
        <main className={c.main}>
          <Banner />
          {filtersCount !== 0 && <Filters />}
          {filteredMap?.doubleDegree && <CourseLabels />}
          <Map />
          {filteredMap?.notes && <Notes notes={filteredMap?.notes} />}
          {filteredMap?.url && <PdfMapUrl urls={filteredMap?.url} />}
        </main>
      )}
    </>
  )
}

export default Main
