import DropdownItem from './DropdownItem'
import DropdownSection from './DropdownSection'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import c from './dropdownWithValue.module.scss'
import Icon from '../../../utils/icons/Icon'
import PositionAwareMenu from '../positionAwareMenu/PositionAwareMenu'

const DropdownWithValue = ({
  placeholder = 'Select',
  onChange,
  ariaLabelledby = 'dropdown-label',
  listId = 'listbox',
  value,
  data,
  categories = false,
}) => {
  const [expanded, setExpanded] = useState(false)

  const [focused, setFocused] = useState(-1)
  const [activeDescendant, setActiveDescendant] = useState()

  //Init item list ref
  const menuItemsRef = useRef([])
  const triggerRef = useRef()

  const containerRef = useRef()
  const [selected, setSelected] = useState(-1)
  const [selectedText, setSelectedText] = useState()
  const [keyInput, setKeyInput] = useState('')
  const [prefilteredIndex, setPrefilteredIndex] = useState()

  //Flat array function for category data
  const flattenArray = (arr) => {
    const flattened = []

    arr.forEach((obj) => {
      flattened.push(...obj.data)
    })

    return flattened
  }
  // Prepare a list of data for index finding
  const dataList = categories ? flattenArray(data) : data

  //Find index if there is refilled value
  const findIndex = useCallback(() => {
    if (value) {
      setSelected(
        dataList.findIndex(
          (item) => JSON.stringify(item.value) === JSON.stringify(value)
        )
      )
    }
  }, [dataList, value])

  useEffect(() => {
    findIndex()
  }, [findIndex])

  useEffect(() => {
    setSelectedText(dataList[selected]?.text)
    setActiveDescendant('option' + selected)
  }, [selected, dataList, expanded])

  useEffect(() => {
    menuItemsRef?.current[selected]?.classList.add(`${c.isSelected}`)
    setFocused(selected)
  }, [menuItemsRef, selected, expanded])

  //Handle click on the dropdown
  const handleOnClick = () => {
    setExpanded(!expanded)
  }

  //Get section starting index
  const getNumMenuItems = (i) => {
    return data[i].data.length === undefined ? 0 : data[i].data.length
  }

  // On focus, update focused.
  // const handleFocus = (i) => {
  //   setFocused(i)
  //   setActiveDescendant('option' + i)
  // }

  // On focus, update selected.
  const handleSelect = (value, i) => {
    setSelected(i)
    onChange(value)

    setActiveDescendant('option' + i)
    setFocused(0)
    setExpanded(false)
  }

  // Arrow keys to navigate menu items.
  const handleKeyDown = (e) => {
    if (e.code === 'Home') {
      if (expanded) {
        e.preventDefault()
        setFocused(0)

        menuItemsRef.current.forEach((element) =>
          element.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[0]?.classList.add(`${c.isSelected}`)

        setActiveDescendant('option' + 0)
      }
    }

    if (e.code === 'End') {
      if (expanded) {
        e.preventDefault()
        setFocused(menuItemsRef.current.length)

        menuItemsRef.current.forEach((element) =>
          element.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[menuItemsRef.current.length - 1]?.classList.add(
          `${c.isSelected}`
        )
        setActiveDescendant('option' + (menuItemsRef.current.length - 1))
      }
    }

    if (e.code === 'PageUp') {
      if (expanded) {
        const next = focused - 10 < 0 ? 0 : focused - 10
        setFocused(next)
        e.preventDefault()
        menuItemsRef.current.forEach((element) =>
          element.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[next]?.classList.add(`${c.isSelected}`)
        setActiveDescendant('option' + next)
      }
    }
    if (e.code === 'PageDown') {
      if (expanded) {
        const next =
          focused + 10 > menuItemsRef.current.length
            ? menuItemsRef.current.length - 1
            : focused + 10
        setFocused(next)
        e.preventDefault()
        menuItemsRef.current.forEach((element) =>
          element.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[next]?.classList.add(`${c.isSelected}`)
        setActiveDescendant('option' + next)
      }
    }

    if (
      e.code === 'ArrowUp' ||
      e.code === 'ArrowLeft'
      // (e.code === 'Tab' && e.shiftKey && expanded)
    ) {
      if (expanded) {
        const next =
          focused === 0 ? menuItemsRef.current.length - 1 : focused - 1
        e.preventDefault()
        setFocused(next)
        menuItemsRef.current.forEach((element) =>
          element.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[next]?.classList.add(`${c.isSelected}`)
        setActiveDescendant('option' + next)
      } else {
        setExpanded(true)
      }
    }

    if (
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight'
      //   (e.code === 'Tab' && !e.shiftKey && expanded)
    ) {
      if (expanded) {
        const next =
          focused === menuItemsRef.current.length - 1 ? 0 : focused + 1

        e.preventDefault()
        setFocused(next)
        menuItemsRef.current.forEach((element) =>
          element.classList.remove(`${c.isSelected}`)
        )

        menuItemsRef.current[next]?.classList.add(`${c.isSelected}`)
        setActiveDescendant('option' + next)
      } else {
        setExpanded(true)
      }
    }

    if (e.code === 'Tab') {
      if (expanded) {
        setSelected(focused)
        setExpanded(false)
      }
    }
    if (e.code === 'Enter') {
      if (expanded) {
        e.preventDefault()
        setSelected(focused)
        setExpanded(false)
        triggerRef.current.focus()
      }
    }

    if (e.code === 'Space') {
      if (expanded) {
        e.preventDefault()
        setSelected(focused)
        setExpanded(false)
        triggerRef.current.focus()
      }
    }
    // Close menu on ESC.
    if (e.code === 'Escape') {
      triggerRef.current.focus()
      setActiveDescendant('option' + selected)
      setExpanded(false)
    }

    //Handle non-control key, jump to the option
    if (e.key) {
      if (e.key.length > 1) return
      const filtered = keyInput + e.key

      setKeyInput(filtered)
      const filteredIndex = data.findIndex((item) =>
        item.text.toLowerCase().startsWith(filtered.toLowerCase())
      )
      if (filteredIndex === -1) {
        setFocused(prefilteredIndex)

        setActiveDescendant('option' + prefilteredIndex)
        menuItemsRef.current.forEach((element) =>
          element?.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[prefilteredIndex]?.classList.add(`${c.isSelected}`)
      } else {
        setFocused(filteredIndex)
        setActiveDescendant('option' + filteredIndex)

        setPrefilteredIndex(filteredIndex)
        menuItemsRef.current.forEach((element) =>
          element?.classList.remove(`${c.isSelected}`)
        )
        menuItemsRef.current[filteredIndex]?.classList.add(`${c.isSelected}`)
      }

      //  setFocused(filteredIndex)
    }
  }
  //Handle click outside
  const onClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setExpanded(false)
    }
  }
  // On mount, add an event to detect clicking outside of the dropdown.
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)

    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  // Clear keys pressed after some delay.
  useEffect(() => {
    if (keyInput === '') return
    let keysTimer = setTimeout(() => setKeyInput(''), 500) // 500ms is the delay used on w3.org

    return () => {
      clearTimeout(keysTimer)
    }
  }, [keyInput])
  // render
  return (
    <div className={c.dropdown} ref={containerRef}>
      <button
        className={
          selectedText
            ? [c.dropdownSelect, c.filled].join(' ')
            : c.dropdownSelect
        }
        onClick={handleOnClick}
        aria-controls={listId}
        aria-expanded={expanded}
        aria-haspopup='listbox'
        aria-labelledby={ariaLabelledby}
        aria-activedescendant={activeDescendant}
        role='combobox'
        tabIndex='0'
        onKeyDown={handleKeyDown}
        ref={triggerRef}>
        {selectedText ? selectedText : placeholder}
        <div className={expanded ? [c.arrow, c.up].join(' ') : c.arrow}>
          <Icon.ChevronDown />
        </div>
      </button>

      <PositionAwareMenu shown={expanded} offsetY={-46}>
        <div
          className={c.selectList}
          role='listbox'
          id={listId}
          aria-labelledby={ariaLabelledby}
          tabIndex='-1'>
          {/* children item array */}
          {categories
            ? data.map((child, i) => (
                <DropdownSection
                  startIndex={i === 0 ? i : getNumMenuItems(i - 1)}
                  key={child.id}
                  selected={selected}
                  category={child.category}
                  categoryLabel={child.categoryLabel}
                  // handleFocus={handleFocus}
                  handleSelect={handleSelect}
                  handleKeyDown={handleKeyDown}
                  handleClose={() => setExpanded(false)}
                  handleSelectText={setSelectedText}
                  innerRef={menuItemsRef}
                  categoryItems={child.data}></DropdownSection>
              ))
            : data.map((child, i) => (
                <DropdownItem
                  index={i}
                  key={child.id}
                  selected={selected}
                  //handleFocus={handleFocus}
                  handleSelect={handleSelect}
                  handleKeyDown={handleKeyDown}
                  handleClose={() => setExpanded(false)}
                  handleSelectText={setSelectedText}
                  innerRef={(e) => (menuItemsRef.current[i] = e)}
                  label={child.label}
                  value={child.value}>
                  {child.text}
                </DropdownItem>
              ))}
        </div>
      </PositionAwareMenu>
    </div>
  )
}

export default DropdownWithValue
