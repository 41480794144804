import React, { createContext, useMemo } from 'react'
import useMedia from '../../utils/hooks/useMedia'

export const SizeContext = createContext()

const SizeContextWrapper = ({ children }) => {
  const size = useMedia()

  const values = useMemo(
    () => ({
      size,
    }),
    [size]
  )

  return <SizeContext.Provider value={values}>{children}</SizeContext.Provider>
}

export default SizeContextWrapper
