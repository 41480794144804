import c from './period.module.scss'
import GridBlock from '../grid-block/GridBlock'
import { capitalise } from '../../../utils/transforms/transform-string'
import { useContext } from 'react'
import { SizeContext } from '../../wrappers/SizeContextWrapper'
const Period = ({ period }) => {
  const { size } = useContext(SizeContext)

  return (
    <li className={c.period}>
      {period.period && <h3>{capitalise(period.period)}</h3>}
      <ul
        className={c.blocks}
        style={{ gridTemplateColumns: `repeat(${size === 'S' ? 2 : 4}, 1fr)` }}>
        {Object.values(period.units).map((block, i) => (
          <GridBlock block={block} key={i} />
        ))}
      </ul>
    </li>
  )
}

export default Period
