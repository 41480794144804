import { nanoid } from 'nanoid'

export const blockify = (
  code,
  description,
  label,
  creditPoints,
  blockIndex,
  periodBlockTypeIndex
) => {
  const newBlock = {
    id: nanoid(),
    name: code || null,
    description,
    courseLabel: label,
    creditPoints: creditPoints,
    blockIndex: blockIndex,
    periodBlockTypeIndex: periodBlockTypeIndex,
  }

  return newBlock
}
