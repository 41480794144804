import React from 'react'
import c from './notes.module.scss'

const Notes = ({ notes }) => {
  return (
    <section aria-label='map notes' className={c.notes}>
      <h2>Notes</h2>
      <p>{notes}</p>
    </section>
  )
}

export default Notes
