import React, { useContext } from 'react'
import CourseLabel from '../../ui/course-label/CourseLabel'
import { CourseMapsContext } from '../../wrappers/CourseMapsContextWrapper'
import c from './course-labels.module.scss'

const CourseLabels = () => {
  const { courseLabels, filteredMap } = useContext(CourseMapsContext)

  return (
    <section aria-label='map labels' className={c.courseLabels}>
      <label>Course labels</label>
      <ul>
        {courseLabels.map((label, i) => (
          <li key={i}>
            <CourseLabel
              label={label}
              displayText={`${label} \u2013 ${filteredMap.singleDegrees[label].title}`}
            />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default CourseLabels
