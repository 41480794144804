import React from 'react'
import Icon from '../../../utils/icons/Icon'
import c from './pdf-map-url.module.scss'

const renderUrls = (urls) =>
  urls.map((url, i) => {
    let chopped = url.split('/')
    let label = chopped[chopped.length - 1]
    return (
      <a href={url} target='_blank' rel='noreferrer' key={i}>
        <span>{label}</span>
        <Icon.External color='var(--color-cta)' />
      </a>
    )
  })

const PdfMapUrl = ({ urls }) => {
  if (urls.length === 0) return

  return (
    <section aria-label='map links' className={c.pdfMapUrl}>
      <h2>Links</h2>
      {renderUrls(urls)}
    </section>
  )
}

export default PdfMapUrl
