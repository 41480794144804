import React, { useContext, useEffect, useState } from 'react'
import { CourseMapsContext } from '../wrappers/CourseMapsContextWrapper'
import c from './banner.module.scss'

const facultyPrefix = {
  A: 'faculty_of_arts',
  B: 'faculty_of_business_and_economics',
  C: 'faculty_of_information_technology',
  D: 'faculty_of_education',
  E: 'faculty_of_engineering',
  F: 'faculty_of_art,_design_and_architecture',
  L: 'faculty_of_law',
  P: 'faculty_of_pharmacy_and_pharmaceutical_sciences',
  S: 'faculty_of_science',
  M: 'faculty_of_medicine,_nursing_and_health_sciences',
}

const Banner = () => {
  const { year, code, onDisplayCourseTitle } = useContext(CourseMapsContext)
  const [bannerImageSrc, setBannerImageSrc] = useState()

  useEffect(() => {
    import(
      `../../assets/images/faculty-banners/${facultyPrefix[code.charAt(0)]}.jpg`
    )
      .then((item) => setBannerImageSrc(item.default))
      .catch(() => {
        import(`../../assets/images/faculty-banners/faculty_of_arts.jpg`)
          .then((item) => setBannerImageSrc(item.default))
          .catch((e) => console.log(e))
      })
  }, [code])

  return (
    <div className={c.banner}>
      <img src={bannerImageSrc} alt='faculty banner' />
      <div>
        <label>{year} Course Progression Map</label>
        <h1>
          {code} {'\u2013'} {onDisplayCourseTitle}
        </h1>
      </div>
    </div>
  )
}

export default Banner
