import React from 'react'
import Logo from '../../utils/icons/Logo'
import c from './top-nav.module.scss'

const TopNav = () => {
  return (
    <header className={c.topNav}>
      <span className={c.appName}>
        <Logo.MonashStacked height={24} /> <div className={c.divider} />
        <p className={c.title}>Course Map Viewer</p>
      </span>
    </header>
  )
}

export default TopNav
