import React, { useContext, useEffect, useRef, useState } from 'react'
import { CourseMapsContext } from '../wrappers/CourseMapsContextWrapper'
import c from './map.module.scss'

import Year from './year/Year'

const Map = () => {
  const { filteredMap } = useContext(CourseMapsContext)
  const [alert, setAlert] = useState(null)
  const previousFilteredMapId = useRef(null)

  const resetAlert = () =>
    setTimeout(() => {
      setAlert('')
    }, 0)

  useEffect(() => {
    // alert screen reader users about map content changes in different scenarios
    // alert message stays the same for now but UX of this should be updated in the future

    if (previousFilteredMapId.current && filteredMap?.id) {
      // Scenario 1: map to map (map content updates)
      setAlert('map content updated')
      resetAlert()
    }

    if (filteredMap?.id !== previousFilteredMapId.current) {
      // Scenario 2: no map to map
      if (!previousFilteredMapId.current && filteredMap?.id) {
        setAlert('map content updated')
        resetAlert()
      }
      // Scenario 3: map to no map
      if (previousFilteredMapId.current && !filteredMap?.id) {
        setAlert('map content updated')
        resetAlert()
      }
    }
    previousFilteredMapId.current = filteredMap?.id
  }, [filteredMap])

  return (
    <>
      {alert && (
        <div role='status' className={c.virtuallyHidden}>
          {alert}
        </div>
      )}
      {!filteredMap && (
        <section aria-label='map content' className={c.noMap}>
          <h3>No course progression map available</h3>
          <p>
            There is currently no course progression map available with the
            selected filters. Please make sure you have made the correct
            selections.
          </p>
        </section>
      )}
      {filteredMap && (
        <section aria-label='map content' className={c.map}>
          <ul className={c.periods}>
            {filteredMap?.map.map((year, i) => (
              <Year year={year} key={i} />
            ))}
          </ul>
        </section>
      )}
    </>
  )
}

export default Map
