import React from 'react'
import Icon from '../../../utils/icons/Icon'
import c from './dropdownWithValue.module.scss'

const DropdownItem = ({
  index,
  selected,
  //handleFocus,
  handleSelect,
  handleKeyDown,
  handleClose,
  handleSelectText,
  innerRef,

  label,
  children,
  value,
}) => {
  const handleOnClick = (e) => {
    e.bubbles = false
    handleSelect && handleSelect(value, index)
    handleSelectText && handleSelectText(children)
  }

  const handleOnKeyDown = (e) => {
    console.log('select')
    handleKeyDown(e)
    handleClose && handleClose()
  }
  return (
    <div
      role='option'
      id={'option' + index}
      className={c.dropdownItem}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      ref={innerRef}
      aria-selected={selected === index}>
      <div className={c.itemText}>
        <span>{children}</span> <span className={c.label}>{label}</span>
      </div>
      {selected === index ? <Icon.Check /> : null}
    </div>
  )
}

export default DropdownItem
